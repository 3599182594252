import request from "@/utils/request.ts";
export default {
  compute(data: any) {
    return request({
      url: "/project/jiJianStudy/compute",
      method: "post",
      data: data,
    });
  },
  selectRandomSubjects() {
    return request({
      url: "/project/jiJianStudy/selectRandomSubjects",
      method: "get",
    });
  },

  rankList(projectId: any) {
    return request({
      url: "/project/jiJianStudy/rankList",
      method: "get",
      params: { projectId: projectId },
    });
  },

  page(data: any) {
    return request({
      url: "/project/jiJianStudy/page",
      method: "post",
      data: data,
    });
  },

  list(data: any) {
    return request({
      url: "/project/jiJianStudy/list",
      method: "post",
      data: data,
    });
  },

  pdf(url: string) {
    return request({
      // url: 'https://wuxi-construction.oss-cn-shanghai.aliyuncs.com/d231eb9c-257d-4ffd-8c4c-eaeca58e6858.pdf',
      url: url,
      method: "get",
      responseType: "blob",
    });
  },

  incrBrowse(jijianId: any) {
    return request({
      url: `/project/jiJianStudy/incrBrowse/${jijianId}`,
      method: "get",
    });
  },

  incrThumbs(jijianId: any) {
    return request({
      url: `/project/jiJianStudy/incrThumbs/${jijianId}`,
      method: "get",
    });
  },

  getBrowseThumbs(jijianId: any) {
    return request({
      url: `/project/jiJianStudy/getBrowseThumbs/${jijianId}`,
      method: "get",
    });
  },

  getBrowseRecordList(data: any) {
    return request({
      url: `/project/jiJianStudy/getBrowseRecordList`,
      method: "get",
      params: data,
    });
  },
  getThumbUpRecordList(params: any) {
    return request({
      url: `/project/jiJianStudy/getThumbUpRecordList`,
      method: "get",
      params: params,
    });
  },
};
