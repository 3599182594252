
import { Vue } from "vue-property-decorator";
import api from "@/api/disciplinesafe.ts";
export default class extends Vue {
  list = [];
  point = 0;
  userInfo = {};
  mounted() {
    this.fGetRankList();
  }
  async fGetRankList() {
    const res: any = await api.rankList(
      this.$store.state.project.projectinfo.projectId
    );
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.list = res.data.rankList;
    this.point = res.data.point;
    this.userInfo = res.data.user;
  }
  rankCompare(num: number) {
    if (this.list.length > 0) {
      let index = this.list.findIndex((item: any, index) => {
        return item.point == num;
      });
      return index === -1 ? "暂无排名" : "第" + index + "名";
    } else {
      return "暂无排名";
    }
  }
}
